@import "../../../jun-styling";

.prompt-view-card {
  text-align: left;
}

.prompt-view-rating-separator {
  margin-top: 1em;
  margin-bottom: 0.5em;
}


.prompt-view-title-line {
  display: flex;
  justify-content: space-between;
}

.prompt-view-title-link {
  text-decoration: none;
}

.prompt-view-title-link:hover {
  text-decoration: underline;
}

.prompt-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
