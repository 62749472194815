.edit-prompt {
  display: flex;
  flex-direction: column;
  gap: 1.5em;
}

.edit-prompt-title-line {
  font-weight: normal!important;
  font-size: medium!important;
}

.edit-prompt-title-feedback {
  font-weight: normal;
  font-size: 0.875em;
}

.edit-prompt-button {
  display: flex;
  flex-direction: row;
  gap: 1ex;

  align-self: flex-end;
  margin: 0 1ex;
}