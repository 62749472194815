.category-label {
  border-radius: 7pt;
  padding: 0 7pt;
  min-height: 1.5em;

  display: inline-flex;
  flex-direction: column;
  justify-content: center;

  overflow-wrap: break-word;
}
