.prompt-view-create-rating {
  display: flex;
  flex-direction: row;
  gap: 1.5em;
  align-items: center;
}

.prompt-view-create-rating-label {
  margin: 0;
}

.prompt-view-create-rating-buttons {
  display: flex;
  flex-direction: row;
  gap: 0.5em;

  font-size: xx-large;
}