@import "../../../jun-styling";

.category-create {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.category-create-body {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.category-create-input {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: $defaultGap;
}

.category-create-input-fields {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 2ex;
}

.category-create-preview {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: calc($maxColumnWidth / 1.5)) {
  .category-create-body {
    flex-wrap: wrap;
    row-gap: $defaultGap;
  }

  .category-create-input {
    width: 100%;
  }

  .category-create-preview {
    width: 100%;
  }
}