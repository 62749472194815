@import "../../jun-styling";

.text-placeholder {
  display: inline;
}

.text-placeholder-view-uneditable-placeholder {
  border: solid $junSeparatorColor 1px;
  border-radius: $defaultBorderRadius;
  background-color: white;
  padding: 6px 12px;

  max-height: 8.5em;
  overflow: auto;
}

.substituted-placeholder {
  border: 1px $junSeparatorColor solid;
}