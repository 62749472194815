.long-text-container {
  display: flex;
  align-items: flex-start;
}

.long-text {
  display: inline;
  overflow: hidden;
  transition: max-height 1s;
}

.long-text-expander {
  margin-top: 0.5em;
}