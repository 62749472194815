.LoadingButton {
    position: relative;
}

.LoadingButton-Spinner {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
}

.hidden {
    visibility: hidden;
    aria-hidden: true;
}

.rotating {
    animation: rotate 1.2s linear infinite both;
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(365deg);
    }
}