@import "../../jun-styling";

.prompt {
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.prompt-button-group {
    display: flex;
    flex-direction: row;
    gap: 1ex;

    align-self: flex-end;
}