.rating-stars {

}

.rating-star-selected {
  color: gold!important;
  fill: gold!important;
}

.rating-star-unselected {
  color: gold!important;
}