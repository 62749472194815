.rating {
  display: inline-flex;
  flex-direction: row;
  gap: 1ex;
  align-items: baseline;
}

.rating-stars {
  position: relative;
  display: inline-block;
  font-size: large;
}

.rating-empty-star {
  color: gold;
}

.rating-full-star-overlay {
  display: block;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  color: gold;
  overflow: hidden;
}