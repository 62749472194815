.create-prompt-container {
  text-align: left;
  padding: 20px;

  display: flex;
  flex-direction: column;
  gap: 1em;
}

.create-prompt-form-group {
  margin-bottom: 20px;
}