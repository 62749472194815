@import "../../../jun-styling";

.answer-form-container {
  display: flex;
  flex-direction: column;
  gap: 1em;
  border: $junSeparatorColor 10px;
}

.answer-form-llm {
  display: flex;
  gap: 1ex;
  justify-content: stretch;
  flex-wrap: wrap;
  row-gap: 0;
  align-items: center;
}

.answer-form-llm-label {
  white-space: nowrap;
  margin-bottom: 0;
}

.answer-form-llm-select {
  width: auto;
}

.answer-form-revision-text {
  display: inline;
  white-space: pre-wrap;

  overflow-y: auto;
  resize: vertical;
  height: calc(5em + 3rem);

  // from bootstrap From.Select:
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius-sm);
}

.answer-form-revision-text-substitution-input {
  width: 100%;
}

.answer-form-button {
  align-self: flex-start;
}