@import "../../../jun-styling";

.category-editable-list-toolbar {
  display: flex;
  flex-direction: row;
}

.category-editable-list-content {
  width: 100%
}

.category-editable-list-input {
  display: flex;
  flex-direction: row;
  gap: $defaultGap;
  align-items: flex-start;
}

.category-editable-list-input-group {
  min-width: 35ex;
  max-width: 35ex;
}

.category-editable-list-input-submit {
  color: $junGreen;
}

.category-editable-list-input-cancel {
  color: $junRed;
  font-weight: bold;
  font-size: large;
}

.category-editable-list-input-color {
  max-width: 5ex;
}