@import "../../jun-styling";

.pagination-container {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  gap: $defaultGap;
}

.pagination-controls {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.pagination-num-pages {
  // own position in its container
  position: absolute;
  align-self: flex-start;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.pagination-entries-per-page {
  // own position in its container
  position: absolute;
  align-self: flex-end;

  // handle positioning of children
  display: flex;
  flex-direction: row;
  gap: 0.5ex;
  align-items: center;
}

.pagination-cur-page {
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-padding-y: 0.375rem;

  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
}