@import "../../jun-styling";

.ViewPrompt {
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 1em;
  align-items: stretch;
}

@media (max-width: 1920px) {
  .ViewPrompt {
    max-width: $maxColumnWidth;
    margin: auto;
  }
}

@media (min-width: 1921px) {
  .ViewPrompt {
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    gap: 5em;

    max-width: calc(2 * $maxColumnWidth + 5em);
    padding: 0 50px;
  }
}

.ViewPrompt-prompt {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.ViewPrompt-prompt-content {
  margin-bottom: 2em;
}

.ViewPrompt-answers {
  width: 100%;
}