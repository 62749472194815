@import "../../../jun-styling";

.CategoryOverviewPage {
  max-width: $maxColumnWidth;
  margin: auto;
  text-align: left;
}

.CategoryOverviewPage-heading {
  text-align: left;
  margin-top: $defaultGap;
  margin-bottom: 1em;
}

.CategoryOverviewPage-categories {
  font-size: larger;
}

.CategoryOverviewPage-category {
  padding: 0.5ex 1ex;
}

.category-edit-toolbar {
  display: inline-flex;
  flex-direction: row;
  gap: 1ex;
  flex-wrap: wrap;
  align-content: center;
}

.category-edit-toolbar-tool {
  padding: 0;
}
