.comment {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.comment-header {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
}

.comment-heading {
  display: inline-block;
}

.comment-timestamp {
  align-self: flex-start;
}

.comment-timestamp-tooltip {
}

.comment-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.comment-add-rating {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1ex;
}