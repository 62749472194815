@import "../../../../jun-styling";

.edit-single-placeholder-description {
  width: 100%;
}


.edit-single-placeholder-key.selected {
}

.edit-single-placeholder-key.unselected {
  color: $junDisabled;
  text-decoration: line-through;
}