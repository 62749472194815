@import "../../jun-styling";

.answer-list {
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1em;
  margin-bottom: 2em;
  margin-top: 1em;
}