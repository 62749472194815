.prompt-list-filter {
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  margin-bottom: 1.5em;
}

.prompt-list-filter-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2ex;
}

.prompt-list-search-bar {
  width: 100%
}

.prompt-list-search-bar-submit {
  color: black!important;
  background-color: var(--bs-tertiary-bg)!important;
  border: var(--bs-border-width) solid var(--bs-border-color)!important;

  --bs-btn-hover-color: black!important;
  --bs-btn-hover-bg: var(--bs-tertiary-bg)!important;
  --bs-btn-hover-border-color: var(--bs-border-color)!important;
  --bs-btn-active-color: black!important;
  --bs-btn-active-bg: white!important;
  --bs-btn-active-border-color: var(--bs-border-color)!important;
}

.prompt-list-filter-categories {
  display: flex;
  align-items: flex-start;
  gap: 1ex;
}

.categories-filter-label {
  margin: 0 8ex 0 0;
}

.categories-filter-select {
  display: flex;
  flex-direction: row;
  margin-bottom: 0.5em;
}

.categories-filter-select-label {
  width: 120px;
  text-align: left;
}